@import "../../../node_modules/bootstrap/less/variables.less";

@icon-font-path: "/assets/fonts/";
@table-border-color: #333;
@navbar-border-radius: 0;
@navbar-inverse-bg: #263238;

@import "../../../node_modules/bootstrap/less/mixins.less";

// Reset and dependencies
@import "../../../node_modules/bootstrap/less/normalize.less";
@import "../../../node_modules/bootstrap/less/print.less";
@import "../../../node_modules/bootstrap/less/glyphicons.less";

// Core CSS
@import "../../../node_modules/bootstrap/less/scaffolding.less";
@import "../../../node_modules/bootstrap/less/type.less";
@import "../../../node_modules/bootstrap/less/code.less";
@import "../../../node_modules/bootstrap/less/grid.less";
@import "../../../node_modules/bootstrap/less/tables.less";
@import "../../../node_modules/bootstrap/less/forms.less";
@import "../../../node_modules/bootstrap/less/buttons.less";

// Components
@import "../../../node_modules/bootstrap/less/component-animations.less";
@import "../../../node_modules/bootstrap/less/dropdowns.less";
@import "../../../node_modules/bootstrap/less/button-groups.less";
@import "../../../node_modules/bootstrap/less/input-groups.less";
@import "../../../node_modules/bootstrap/less/navs.less";
@import "../../../node_modules/bootstrap/less/navbar.less";
@import "../../../node_modules/bootstrap/less/breadcrumbs.less";
@import "../../../node_modules/bootstrap/less/pagination.less";
@import "../../../node_modules/bootstrap/less/pager.less";
@import "../../../node_modules/bootstrap/less/labels.less";
@import "../../../node_modules/bootstrap/less/badges.less";
@import "../../../node_modules/bootstrap/less/jumbotron.less";
@import "../../../node_modules/bootstrap/less/thumbnails.less";
@import "../../../node_modules/bootstrap/less/alerts.less";
@import "../../../node_modules/bootstrap/less/progress-bars.less";
@import "../../../node_modules/bootstrap/less/media.less";
@import "../../../node_modules/bootstrap/less/list-group.less";
@import "../../../node_modules/bootstrap/less/panels.less";
@import "../../../node_modules/bootstrap/less/responsive-embed.less";
@import "../../../node_modules/bootstrap/less/wells.less";
@import "../../../node_modules/bootstrap/less/close.less";

// Components w/ JavaScript
@import "../../../node_modules/bootstrap/less/modals.less";
@import "../../../node_modules/bootstrap/less/tooltip.less";
@import "../../../node_modules/bootstrap/less/popovers.less";
@import "../../../node_modules/bootstrap/less/carousel.less";

// Utility classes
@import "../../../node_modules/bootstrap/less/utilities.less";
@import "../../../node_modules/bootstrap/less/responsive-utilities.less";


.mute {
    background: #dddddd;
}

tr.highlight td, tr.highlight-green td {
    background-color: #ceffce;
}

tr.highlight-yellow td {
    background-color: #e5ff7c;
}

.table-simple tbody {
    > tr, > tr > td {
        padding: 0;
        margin: 0;
        border: none !important;
    }
}

.note-editor.note-frame {
    line-height: 1;

    p {
        margin-bottom: 5px;
    }
}

.note-editor.note-frame .note-editing-area .note-editable {
    padding-top: 20px !important;
}

.checkbox-array label {
    padding-left: 15px;
    text-indent: -15px;
}

.checkbox-array input {
    width: 13px;
    height: 13px;
    padding: 0px;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -3px;
    overflow: hidden;
}

.error {
    color: red;
    margin-bottom:15px;
}

.checkbox-array-item {
    cursor: pointer;
}

.checkbox-array-item:hover {
    background-color:#ededed;
}

@grid-textarea-min: 160px;
@grid-textarea-max: 400px;

.grid {
    .grid-cmd-btn {
        margin-top: 5px;
        float: right;
    }
}

.grid-table {
    th {
        text-align: center !important;
        padding:5px !important;
    }

    td {
        padding:5px !important;
        max-height: 300px !important;
        min-width: 100px;
    }

    tr {
        max-height: 300px;
    }

    input {
        text-align: center;
    }

    input[type=checkbox] {
        width: 20px;
        height: 20px;
    }

    textarea {
        min-width: @grid-textarea-min;
        max-width: @grid-textarea-max;
        min-height:50px;
        width: 100%;
        max-height: 400px;
        height: auto;
    }

    .borderless {
        border-top: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;
        padding: 10px;
    }

    .grid-cell {
        width: 100%;
        max-height: 200px;
        margin: 0px;
        padding: 0px;
        overflow: auto;
    }

    .checkbox-container {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .note-editable .note-editor {
        text-align: left;
    }

    .grid-summernote {
        min-width: 330px !important;
    }
}

.bill-table {
    width: 90%;
    margin-left: 10px;
}


.note-editor {
    li {
        margin-top: 12px;
    }
}

.encounter_page {    img {
        max-width: 100%;
    }
}

.expand-btn {
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}

.expand-btn:hover {
    color: #20314c;
}

.collapse-btn {
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}

.collapse-btn:hover {
    color: #20314c;
}

.summernote-image {
    max-width: 100%;
}

.modal-dialog {
    z-index: 1040;
}

.cui-field p {
    margin: 0px;
}

.tooltip-trigger {
    margin-left: 5px;
    margin-top: 2px;
    color: grey;
}

.tooltip-trigger:hover {
    color: darken(grey, 25%);
}

.inactive {
    color: grey;
}

.active {
    color: blue;
}

.sort-button {
    cursor: pointer;
}